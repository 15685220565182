import { buttonColors, textColors } from '@styles/_variables';
import { transparentColor } from '@styles/_mixins';

import { TBaseButtonTheme } from './_types';

export interface IBaseButtonThemeColors<
  T = {
    textColor: string;
    backgroundColor: string;
    border?: string;
    opacity?: string;
  }
> {
  base: T;
  hover: T;
  active: T;
  disabled: T;
}

export const baseButtonThemeColors: Record<
  TBaseButtonTheme,
  IBaseButtonThemeColors
> = {
  blue: {
    base: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['blue'],
    },
    hover: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['blue_light'],
    },
    active: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['blue_deep'],
    },
    disabled: {
      textColor: textColors['grey'],
      backgroundColor: buttonColors['blue_dark'],
    },
  },
  green: {
    base: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['green'],
    },
    hover: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['green_light'],
    },

    active: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['green_deep'],
    },

    disabled: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['green_dark'],
    },
  },
  coral: {
    base: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['coral'],
    },
    hover: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['coral_light'],
    },
    active: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['coral_deep'],
    },
    disabled: {
      textColor: textColors['grey'],
      backgroundColor: buttonColors['coral_dark'],
    },
  },
  yellow: {
    base: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['yellow'],
    },
    hover: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['yellow_light'],
    },
    active: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['yellow_deep'],
    },
    disabled: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['yellow_dark'],
    },
  },
  yellow_inverted: {
    base: {
      textColor: textColors['yellow'],
      backgroundColor: 'transparent',
      border: `1px solid ${transparentColor(buttonColors['yellow'], 0.3)}`,
    },

    hover: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['yellow'],
    },

    active: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['yellow_deep'],
    },

    disabled: {
      textColor: `${transparentColor(textColors['yellow'], 0.3)}`,
      backgroundColor: 'transparent',
      border: `1px solid ${buttonColors['yellow_dark']}`,
    },
  },
  brown: {
    base: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['brown'],
    },

    hover: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['brown_light'], // TODO: check color
    },
    active: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['brown_deep'],
    },
    disabled: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['brown_dark'],
    },
  },
  brown_inverted: {
    base: {
      textColor: textColors['brown'],
      backgroundColor: 'transparent',
      border: `1px solid ${transparentColor(buttonColors['brown'], 0.3)}`,
    },

    hover: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['brown'],
    },

    active: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['brown_deep'],
    },

    disabled: {
      textColor: textColors['brown_deep'],
      backgroundColor: 'transparent',
      border: `1px solid ${buttonColors['brown_dark']}`,
    },
  },
  white: {
    base: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['white'],
    },

    hover: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['grey_light'],
    },

    active: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['grey'],
    },

    disabled: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['grey_middle'],
    },
  },
  white_inverted: {
    base: {
      textColor: textColors['white'],
      backgroundColor: 'transparent',
      border: `1px solid ${buttonColors['white']}`,
    },

    hover: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['white'],
    },

    active: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['grey_light'],
    },

    disabled: {
      textColor: buttonColors['grey_middle'],
      backgroundColor: 'transparent',
      border: `1px solid ${buttonColors['grey_middle']}`,
    },
  },
  coral_inverted: {
    base: {
      textColor: textColors['coral'],
      backgroundColor: 'transparent',
      border: `1px solid ${buttonColors['coral']}`,
    },

    hover: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['coral'],
    },

    active: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['coral_deep'],
    },

    disabled: {
      textColor: buttonColors['coral_deep'], // TODO: check color
      backgroundColor: 'transparent',
      border: `1px solid ${buttonColors['coral_deep']}`,
    },
  },
  pink: {
    base: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['pink'],
    },
    hover: {
      textColor: textColors['black'],
      backgroundColor: transparentColor(buttonColors['pink'], 0.8), // TODO: different color: ;
    },
    active: {
      textColor: textColors['black'],
      backgroundColor: transparentColor(buttonColors['pink'], 0.6),
    },
    disabled: {
      textColor: textColors['grey'],
      backgroundColor: transparentColor(buttonColors['pink'], 0.4),
    },
  },
  black_inverted: {
    base: {
      textColor: textColors['black'],
      backgroundColor: 'transparent',
      border: `1px solid ${buttonColors['black']}`,
    },

    hover: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['black'],
    },

    active: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['grey_light'],
    },

    disabled: {
      textColor: buttonColors['grey_middle'],
      backgroundColor: 'transparent',
      border: `1px solid ${buttonColors['grey_middle']}`,
    },
  },
  light_blue: {
    base: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['light_blue'],
    },
    hover: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['light_blue_lighter'],
    },

    active: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['light_blue_darker'],
    },

    disabled: {
      textColor: textColors['white'],
      backgroundColor: buttonColors['light_blue'],
      opacity: '0.33',
    },
  },
  bluish_green: {
    base: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['bluish_green'],
    },
    hover: {
      textColor: textColors['black'],
      backgroundColor: transparentColor(buttonColors['bluish_green'], 0.9),
    },
    active: {
      textColor: textColors['black'],
      backgroundColor: transparentColor(buttonColors['bluish_green'], 0.8),
    },
    disabled: {
      textColor: textColors['black'],
      backgroundColor: buttonColors['bluish_green'],
      opacity: '0.33',
    },
  },
};
