import React, { useMemo } from 'react';

import { IBaseButton } from './_types';
import { $Button } from './_styles';
import { baseButtonThemeColors, IBaseButtonThemeColors } from './_themes';

export const BaseButton: React.FC<IBaseButton> = React.memo(
  ({
    children,
    onClick,
    style,
    disabled,
    buttonType = 'primary',
    theme = 'blue',
    className = '',
  }) => {
    const themeData = useMemo<IBaseButtonThemeColors>(() => {
      return baseButtonThemeColors[theme];
    }, [theme]);

    return (
      <$Button
        className={className}
        buttonType={buttonType}
        themeData={themeData}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        {children}
      </$Button>
    );
  }
);

BaseButton.displayName = 'BaseButton';
